import React from "react";
import { OrderInterface, StoreInterface } from "../../../assets/interfaces";
import { INVOICE_STATUSES } from "../../../assets/interfaces/invoices";
import getWhatsappLink from "../../../assets/js/utils/get-whatsapp-order-link";
import { useListenerState } from "../../hooks/useListener";
import MakePayment from "../../payments-and-invoices";
import { AppBtn } from "../../ui/buttons";
import { ModalBody, ModalFooter } from "../../ui/modal";
import SuccessAnimation from "../../ui/success-animation";
import { sendFacebookEvent } from "@/assets/js/utils/utils";

interface MakeOrderPaymentProps {
  store: StoreInterface;
  closeModal: () => void;
  order: OrderInterface;
  prevStep: VoidFunction;
}

const MakeOrderPayment: React.FC<MakeOrderPaymentProps> = ({ store, order, closeModal, prevStep }) => {
  const [showCartBackButton, setShowCartBackButton] = useListenerState("payment-not-started", true);

  return (
    <>
      <ModalBody>
        <MakePayment
          invoiceData={{
            storeName: store.name,
            storePhone: store.phone,
            initial_status: INVOICE_STATUSES.PENDING,
            preview_amount: order.total_amount,
            invoice: order?.invoice as string,
            currency: order.currency,
          }}
          orderId={order.id}
          order={order}
          onPaymentSuccess={() =>
            sendFacebookEvent("Purchase", {
              value: order?.total_amount,
              currency: order?.currency,
              content_ids: order?.items.map((i) => i.id),
              num_items: order?.items?.length,
              contents: order.items.map((item) => ({
                id: item.id,
                quantity: item.quantity,
                item_price: item.item.price,
              })),
            })
          }
        />
      </ModalBody>
      {showCartBackButton && (
        <ModalFooter>
          <AppBtn size="lg" isBlock color="neutral" onClick={prevStep}>
            Go Back
          </AppBtn>
        </ModalFooter>
      )}
    </>
  );
};

export default MakeOrderPayment;
